const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

const userManagementUrl = 'user-management/';
const drugIndexUrl = 'drug-index/';
const diseaseUrl = 'disease/';
const diagnosisUrl = 'diagnosis/';
const paymentUrl = '/payment/';
const userHomeUrl = 'web/';

export default {
    auth: {
        login: baseUrl + userManagementUrl + 'login/',
        status: baseUrl + userManagementUrl + 'status/',
        logout: baseUrl + userManagementUrl + 'logout/',
        changePassword: baseUrl + userManagementUrl + 'change-password/',
        forgotPassword: baseUrl + userManagementUrl + 'forgot-password/',
        googleLogin: baseUrl + userManagementUrl + 'google-login/',
        googleLogout: baseUrl + userManagementUrl + 'google-login/'// check
    },
    userManagement: {
        staff: {
            list: baseUrl + userManagementUrl + 'staff-list/',
            addEdit: baseUrl + userManagementUrl + 'staff-add-edit/',
            staffBan: baseUrl + userManagementUrl + 'staff-ban/'
        },
        plan: {
            addEdit: baseUrl + userManagementUrl + 'plan-add-edit/',
            list: baseUrl + userManagementUrl + 'plan-list/',
            detail: baseUrl + userManagementUrl + 'plan-detail/',
            delete: baseUrl + userManagementUrl + 'plan-delete/',
            activate: baseUrl + userManagementUrl + 'plan-activate/',
            activePlanList: baseUrl + userManagementUrl + 'active-plan-list/',
            deletedPlanList: baseUrl + userManagementUrl + 'deleted-plan-list/'
        },
        users: {
            addEdit: baseUrl + userManagementUrl + 'users-add-edit/',
            list: baseUrl + userManagementUrl + 'users-list/',
            detail: baseUrl + userManagementUrl + 'users-detail/',
            delete: baseUrl + userManagementUrl + 'users-delete/',
            userBan: baseUrl + userManagementUrl + 'users-ban/',
            transactions: baseUrl + userManagementUrl + 'users-transactions/',
            pendingApprovalList: baseUrl + userManagementUrl + 'pending-approval-list/',
            usersApprove: baseUrl + userManagementUrl + 'users-approve/',
            usersReject: baseUrl + userManagementUrl + 'users-reject/',
            pendingApprovalDetail: baseUrl + userManagementUrl + 'pending-approval-detail/',
            rejectedUsersList: baseUrl + userManagementUrl + 'rejected-users-list/', // check urls
            bannedUsersList: baseUrl + userManagementUrl + 'banned-users-list/'
        }
    },
    directory: {
        directoryItems: {
            // addEdit: baseUrl + 'drug-index/category-add-edit/',
            addEdit: baseUrl + 'drug-index/categories/add-edit/',
            details: baseUrl + 'drug-index/category-detail/',
            // list: baseUrl + 'drug-index/category-list/',
            list: baseUrl + 'drug-index/categories/list/',
            // delete: baseUrl + 'drug-index/category-delete/',
            delete: baseUrl + 'drug-index/categories/delete/'
        },
        category: {
            addEdit: baseUrl + 'drug-index/directory-category/add-edit/',
            details: baseUrl + 'drug-index/category-detail/',
            list: baseUrl + 'drug-index/directory-category/list/',
            delete: baseUrl + 'drug-index/directory-category/delete/',
            vueSelect: baseUrl + 'drug-index/directory-category/vue-select/'
            // vueSelect: baseUrl + 'drug-index/category-vue-select/'
        },
        batch: {
            list: baseUrl + 'drug-index/batch-list/',
            addEdit: baseUrl + 'drug-index/batch-add-edit/',
            delete: baseUrl + 'drug-index/batch-delete/', // check
            detail: baseUrl + 'drug-index/batch-detail/'
        },
        contact: {
            list: baseUrl + 'drug-index/contact-list/',
            addEdit: baseUrl + 'drug-index/contact-add-edit/',
            delete: baseUrl + 'drug-index/contact-delete/', // check
            detail: baseUrl + drugIndexUrl + 'contact-detail/'
        },
        contactsAll: {
            list: baseUrl + drugIndexUrl + 'contacts-all-list/',
            addEdit: baseUrl + drugIndexUrl + 'contacts-all-add-edit/',
            delete: baseUrl + drugIndexUrl + 'contacts-all-delete/', // check
            detail: baseUrl + drugIndexUrl + 'contacts-all-detail/'
        },
        departmentAnimalHusbandary: {
            list: baseUrl + drugIndexUrl + 'animal-husbandary-list/',
            addEdit: baseUrl + drugIndexUrl + 'animal-husbandary-add-edit/',
            delete: baseUrl + drugIndexUrl + 'animal-husbandary-delete/', // check
            detail: baseUrl + drugIndexUrl + 'animal-husbandary-detail/'
        },
        farm: {
            list: baseUrl + drugIndexUrl + 'farm-list/',
            addEdit: baseUrl + drugIndexUrl + 'farm-add-edit/',
            delete: baseUrl + drugIndexUrl + 'farm-delete/', // check
            detail: baseUrl + drugIndexUrl + 'farm-detail/'
        },
        universityOffice: {
            list: baseUrl + drugIndexUrl + 'university-list/',
            addEdit: baseUrl + drugIndexUrl + 'university-add-edit/',
            delete: baseUrl + drugIndexUrl + 'university-delete/', // check
            detail: baseUrl + drugIndexUrl + 'university-detail/'
        },
        vetDoc: {
            list: baseUrl + drugIndexUrl + 'vet-doc-list/',
            addEdit: baseUrl + drugIndexUrl + 'vet-doc-add-edit/',
            delete: baseUrl + drugIndexUrl + 'vet-doc-delete/', // check
            detail: baseUrl + drugIndexUrl + 'vet-doc-detail/'
        }
    },
    drugs: {
        drugCategory: {
            addEdit: baseUrl + drugIndexUrl + 'drug-category-add-edit/',
            list: baseUrl + drugIndexUrl + 'drug-category-list/',
            delete: baseUrl + drugIndexUrl + 'drug-category-delete/', // check
            vueSelect: baseUrl + drugIndexUrl + 'drug-category-vue-select/'// check
        },
        drug: {
            addEdit: baseUrl + drugIndexUrl + 'drug-add-edit/',
            list: baseUrl + drugIndexUrl + 'drug-list/',
            detail: baseUrl + drugIndexUrl + 'drug-detail/',
            delete: baseUrl + drugIndexUrl + 'drug-delete/'// check
        },
        brand: {
            addEditUrl: baseUrl + drugIndexUrl + 'brand-add-edit/',
            listUrl: baseUrl + drugIndexUrl + 'brand-list/',
            detail: baseUrl + drugIndexUrl + 'brand-detail/',
            delete: baseUrl + drugIndexUrl + 'brand-delete/', // check
            recommendedBrandView: baseUrl + drugIndexUrl + 'recommended-brand-list/', // check
            recommendedBrandList: baseUrl + drugIndexUrl + 'recommended-brand-list/'// check
        },
        report: {
            addEdit: baseUrl + drugIndexUrl + 'brand-add-edit/',
            list: baseUrl + drugIndexUrl + 'drug-report-list/',
            fixedReportList: baseUrl + drugIndexUrl + 'drug-report-fixed-list/',
            drugReportFixed: baseUrl + drugIndexUrl + 'drug-report-fixed/'
        },
        flash: {
            addEdit: baseUrl + drugIndexUrl + 'flash-add-edit/',
            list: baseUrl + drugIndexUrl + 'flash-list/',
            delete: baseUrl + drugIndexUrl + 'flash-delete/',
            enableUrl: baseUrl + drugIndexUrl + 'flash-enable-disable/'
        },
        listing: {
            addEdit: baseUrl + drugIndexUrl + 'listing-add-edit/',
            list: baseUrl + drugIndexUrl + 'listing-list/',
            listVueSelect: baseUrl + drugIndexUrl + 'listing-vue-select/',
            delete: baseUrl + drugIndexUrl + 'listing-delete/'
        },
        brandExcel: {
            excel: baseUrl + drugIndexUrl + 'brands-excel/'
        }
    },
    advertisement: {
        addEdit: baseUrl + drugIndexUrl + 'advertisement-add-edit/',
        list: baseUrl + drugIndexUrl + 'advertisement-list/',
        detail: baseUrl + drugIndexUrl + 'advertisement-detail/',
        delete: baseUrl + drugIndexUrl + 'advertisement-delete/', // check
        listVueSelect: baseUrl + drugIndexUrl + 'advertisement-vue-select/'// check
    },
    article: {
        oldArticle: {
            addEdit: baseUrl + drugIndexUrl + 'old-article-add-edit/',
            list: baseUrl + drugIndexUrl + 'old-article-list/',
            detail: baseUrl + drugIndexUrl + 'old-article-detail/',
            delete: baseUrl + drugIndexUrl + 'old-article-delete/',
            vueSelect: baseUrl + drugIndexUrl + 'old-article-vue-select/'
        },
        oldArticleImage: {
            addEditUrl: baseUrl + drugIndexUrl + 'article-image-add-edit/',
            ListUrl: baseUrl + drugIndexUrl + 'article-image-list/',
            deleteUrl: baseUrl + drugIndexUrl + 'article-image-delete/'
        },
        newArticle: {
            addEditUrl: baseUrl + drugIndexUrl + 'new-article-add-edit/',
            listUrl: baseUrl + drugIndexUrl + 'new-article-list/',
            detail: baseUrl + drugIndexUrl + 'new-article-detail/',
            delete: baseUrl + drugIndexUrl + 'new-article-delete/',
            vueSelect: baseUrl + drugIndexUrl + 'old-article-vue-select/'
        }
    },
    disease: {
        disease: {
            addEdit: baseUrl + diseaseUrl + 'add-edit/',
            list: baseUrl + diseaseUrl + 'list/',
            detail: baseUrl + diseaseUrl + 'detail/',
            vueSelect: baseUrl + diseaseUrl + 'vue-select/',
            delete: baseUrl + diseaseUrl + 'delete/'
        },
        diseaseCategory: {
            addEdit: baseUrl + diseaseUrl + 'category-add-edit/',
            list: baseUrl + diseaseUrl + 'category-list/',
            vueSelect: baseUrl + diseaseUrl + 'category-vue-select/',
            delete: baseUrl + diseaseUrl + 'category-delete/'
        },
        diseaseImage: {
            addEdit: baseUrl + diseaseUrl + 'image-add-edit/',
            list: baseUrl + diseaseUrl + 'image-list/',
            delete: baseUrl + diseaseUrl + 'image-delete/'
        },
        diseaseDescription: {
            addEdit: baseUrl + diseaseUrl + 'description-add-edit/',
            list: baseUrl + diseaseUrl + 'description-list/',
            delete: baseUrl + diseaseUrl + 'description-delete/'
        },
        diseaseReport: {
            indication: baseUrl + diseaseUrl + 'indications-report-list/',
            preparation: baseUrl + diseaseUrl + 'preparations-report-list/',
            disease: baseUrl + diseaseUrl + 'disease-report-list/',
            delete: baseUrl + diseaseUrl + 'reports-delete/'
        },
        preparationSuggestionApproved: baseUrl + diseaseUrl + 'preparations-suggestion-approved-list/',
        indicationSuggestionApproved: baseUrl + diseaseUrl + 'indications-suggestion-approved-list/',

        treatment: {
            addEdit: baseUrl + diseaseUrl + 'treatments-add-edit/',
            list: baseUrl + diseaseUrl + 'treatments-list/',
            delete: baseUrl + diseaseUrl + 'treatments-delete/'
        },
        treatmentsSuggestions: {
            addEdit: baseUrl + diseaseUrl + 'treatment-suggestion-add-edit/',
            list: baseUrl + diseaseUrl + 'treatment-suggestion-approved-list/',
            delete: baseUrl + diseaseUrl + 'treatments-delete/'
        },
        treatmentsAddSuggestionList: {
            addEdit: baseUrl + diseaseUrl + 'treatment-suggestion-add-edit/',
            list: baseUrl + diseaseUrl + 'add-suggestion-list/',
            approve: baseUrl + diseaseUrl + 'treatment-suggestion-approve/',
            treatmentList: baseUrl + diseaseUrl + 'treatment-suggestion-list/',
            sugestedVueSelect: baseUrl + diseaseUrl + 'suggested-vue-select/',
            delete: baseUrl + diseaseUrl + 'treatment-suggestion-delete/'
        },
        suggestion: {
            addEdit: baseUrl + diseaseUrl + 'treatment-suggestion-add-edit/',
            list: baseUrl + diseaseUrl + 'add-suggestion-list/',
            preparation: baseUrl + diseaseUrl + 'preparations-vue-select/',
            indications: baseUrl + diseaseUrl + 'indications-vue-select/',
            treatmentList: baseUrl + diseaseUrl + 'treatment-suggestion-list/',
            sugestedVueSelect: baseUrl + diseaseUrl + 'suggested-vue-select/',
            delete: baseUrl + diseaseUrl + 'treatment-suggestion-delete/'
        },
        treamentSuggestionApproved: {
            addEdit: baseUrl + diseaseUrl + 'treatment-suggestion-add-edit/',
            list: baseUrl + diseaseUrl + 'treatment-suggestion-approved-list/',
            preparation: baseUrl + diseaseUrl + 'preparations-vue-select/',

            delete: baseUrl + diseaseUrl + 'treatment-suggestion-delete/'
        },
        dictionary: {
            addEdit: baseUrl + diseaseUrl + 'dictionary-add-edit/',
            list: baseUrl + diseaseUrl + 'dictionary-list/',
            delete: baseUrl + diseaseUrl + 'dictionary-delete/'
        },
        diseaseRequests: {
            list: baseUrl + diseaseUrl + 'disease-requests-list/',
            delete: baseUrl + diseaseUrl + 'disease-requests-delete/'
        },
        userFavourites: {
            list: baseUrl + diseaseUrl + 'user-favourites-list/'
        },
        dictionaryExcelUpload: {
            addEdit: baseUrl + diseaseUrl + 'dictionary-excel-upload/'
        }
    },
    pharmacy: {
        indication: {
            addEdit: baseUrl + diseaseUrl + 'indications-add-edit/',
            list: baseUrl + diseaseUrl + 'indications-list/',
            delete: baseUrl + diseaseUrl + 'indications-delete/',
            indicationSuggestionList: baseUrl + diseaseUrl + 'indications-suggestion-list/',
            detail: baseUrl + diseaseUrl + 'indications-detail/',
            vueSelect: baseUrl + diseaseUrl + 'indications-vue-select/',
            report: baseUrl + diseaseUrl + 'indications-report-list/',
            reportDelete: baseUrl + diseaseUrl + 'reports-delete/',
            suggestionList: baseUrl + diseaseUrl + 'indications-suggestion-list/',
            suggestionDelete: baseUrl + diseaseUrl + 'treatment-suggestion-delete/',
            suggestionAddEdit: baseUrl + diseaseUrl + 'indications-suggestion-add-edit/',
            suggestionApprove: baseUrl + diseaseUrl + 'treatment-suggestion-approve/',
            suggestedVueSelect: baseUrl + diseaseUrl + 'suggested-vue-select/'
        },
        preparation: {
            addEdit: baseUrl + diseaseUrl + 'preparations-add-edit/',
            list: baseUrl + diseaseUrl + 'preparations-list/',
            delete: baseUrl + diseaseUrl + 'preparations-delete/',
            suggestionList: baseUrl + diseaseUrl + 'preparations-suggestion-list/',
            detail: baseUrl + diseaseUrl + 'preparations-detail/',
            vueSelect: baseUrl + diseaseUrl + 'preparations-vue-select/',
            suggestionDelete: baseUrl + diseaseUrl + 'treatment-suggestion-delete/',
            suggestionAddEdit: baseUrl + diseaseUrl + 'preparations-suggestion-add-edit/',
            suggestedVueSelect: baseUrl + diseaseUrl + 'suggested-vue-select/',
            report: baseUrl + diseaseUrl + 'preparations-report-list/',
            reportDelete: baseUrl + diseaseUrl + 'reports-delete/',
            suggestionApprove: baseUrl + diseaseUrl + 'treatment-suggestion-approve/',
            indiacationVueSelect: baseUrl + diseaseUrl + 'indications-vue-select/',
            preparationDetail: baseUrl + diseaseUrl + 'preparations-detail/'

        }
    },
    diagnosis: {
        normalValues: {
            DetailsList: baseUrl + diagnosisUrl + 'normal-values-detail/',
            list: baseUrl + diagnosisUrl + 'normal-values-list/',
            addEdit: baseUrl + diagnosisUrl + 'normal-values-add-edit/',
            delete: baseUrl + diagnosisUrl + 'normal-values-delete/',
            species: baseUrl + diseaseUrl + 'category-vue-select/'
        },
        specialTest: {
            list: baseUrl + diagnosisUrl + 'test-values-list/',
            addEdit: baseUrl + diagnosisUrl + 'test-values-add-edit/',
            delete: baseUrl + diagnosisUrl + 'test-values-delete/',
            species: baseUrl + diseaseUrl + 'category-vue-select/'
        },
        clinicalSigns: {
            list: baseUrl + diagnosisUrl + 'clinical-signs-list/',
            addEdit: baseUrl + diagnosisUrl + 'clinical-signs-add-edit/',
            delete: baseUrl + diagnosisUrl + 'clinical-signs-delete/',
            species: baseUrl + diseaseUrl + 'category-vue-select/'
        },
        completeBloodCount: {
            list: baseUrl + diagnosisUrl + 'blood-count-list/',
            addEdit: baseUrl + diagnosisUrl + 'blood-count-add-edit/',
            diseaseVueSelect: baseUrl + diseaseUrl + 'vue-select/',
            speciesVueSelect: baseUrl + diseaseUrl + 'category-vue-select/',
            delete: baseUrl + diagnosisUrl + 'blood-count-delete/'
        },
        bloodChemistry: {
            list: baseUrl + diagnosisUrl + 'blood-chemistry-list/',
            addEdit: baseUrl + diagnosisUrl + 'blood-chemistry-add-edit/',
            delete: baseUrl + diagnosisUrl + 'blood-chemistry-delete/',
            diseaseVueSelect: baseUrl + diseaseUrl + 'vue-select/',
            speciesVueSelect: baseUrl + diseaseUrl + 'category-vue-select/'
        },
        urineAnalysis: {
            list: baseUrl + diagnosisUrl + 'urine-analysis-list/',
            addEdit: baseUrl + diagnosisUrl + 'urine-analysis-add-edit/',
            delete: baseUrl + diagnosisUrl + 'urine-analysis-delete/',
            diseaseVueSelect: baseUrl + diseaseUrl + 'vue-select/',
            speciesVueSelect: baseUrl + diseaseUrl + 'category-vue-select/'
        },
        pathologicalFindings: {
            list: baseUrl + diagnosisUrl + 'pathological-list/',
            addEdit: baseUrl + diagnosisUrl + 'pathological-add-edit/',
            delete: baseUrl + diagnosisUrl + 'pathological-delete/',
            diseaseVueSelect: baseUrl + diseaseUrl + 'vue-select/',
            speciesVueSelect: baseUrl + diseaseUrl + 'category-vue-select/'
        },
        ascalation: {
            listUrl: baseUrl + diagnosisUrl + 'ascalation-list/',
            addEdit: baseUrl + diagnosisUrl + 'ascalation-add-edit/',
            deleteUrl: baseUrl + diagnosisUrl + 'ascalation-delete/',
            diseaseVueSelect: baseUrl + diseaseUrl + 'vue-select/',
            speciesVueSelect: baseUrl + diseaseUrl + 'category-vue-select/'
        }

    },
    transactions: {
        list: paymentUrl + 'transaction-list/',
        detail: paymentUrl + 'transaction-detail/',
        refundList: paymentUrl + 'refund-list/',
        refundDetail: paymentUrl + 'refund-detail/',
        refund: paymentUrl + 'refund-create/'
    },
    dashBord: {
        details: baseUrl + 'home/dashboard/'
    },

    // User Components

    /* userDictionary: {
        /!* list: *!/
    }, */
    userHome: {
        banners: {
            list: baseUrl + userHomeUrl + 'advertisement/'
        },
        disease: {
            list: baseUrl + userHomeUrl + 'disease-list/',
            detail: baseUrl + userHomeUrl + 'disease-detail/',
            reportDisease: baseUrl + userHomeUrl + 'report-disease/',
            suggestDisease: baseUrl + userHomeUrl + 'suggest-disease/',
            requestDisease: baseUrl + userHomeUrl + 'request-disease/'
        },
        userDictionary: {
            list: baseUrl + userHomeUrl + 'dictionary-list/'
        },
        userHomeFavourites: {
            list: baseUrl + userHomeUrl + 'favourites-list/',
            add: baseUrl + userHomeUrl + 'add-to-favourites/'
        },
        preparation: {
            list: baseUrl + userHomeUrl + 'preparation-list/',
            suggestPreparation: baseUrl + userHomeUrl + 'suggest-preparation/',
            reportPreparation: baseUrl + userHomeUrl + 'report-preparation/',
            details: baseUrl + userHomeUrl + 'preparation-detail/'

        },
        userHomeDiagnose: {
            list: baseUrl + userHomeUrl + 'diagnosis-list/',
            diagnose: baseUrl + userHomeUrl + 'diagnose/'

        },
        payment: {
            list: baseUrl + 'app/subscription-plans/'
            /*  diagnose: baseUrl + userHomeUrl + 'diagnose/' */

        }
    }

};
